import React from "react";
import "./App.css";

function App() {
  return (
    <>
      <header className="flex justify-center my-12">
        <img src="/logo.svg" alt="" className="w-24" />
      </header>
      <section className="text-center  mb-24">
        <h1 className="text-4xl font-extrabold sm:text-5xl lg:text-6xl">
          A tiny place for great memories
        </h1>
        <p className="mt-4  text-lg">
          I løpet av August 2022 åpner det en ny og unik overnattingsplass på
          Kvitsøy i Rogaland.
        </p>
      </section>
      {/* Hero card */}
      <div className="relative">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="sm:rounded-2xl sm:overflow-hidden">
            <img
              className="h-screen w-full object-cover"
              src="/lodge-min.png"
              alt="People working on laptops"
            />
          </div>
        </div>
      </div>

      <section className="text-center mb-24 max-w-4xl mx-auto my-12">
        <p className="mt-4 text-lg">
          Lauritz Lodge er etablert på eiendommen til tidligere marinemaler
          Lauritz Haaland i Ydstebøhavn. Kunsten, norsk design og nærheten til
          Kvitsøy fyr vil gi denne overnattingsplassen en unik opplevelse.
        </p>

        <div className="my-12">
          <ul>
            <li className="mb-4">
              <span className="font-extrabold">Kontakt:</span>
            </li>
            <li>Anne Berit Olsen</li>
            <li>Lauritz Haalands vei 2</li>
            <li>4180 Kvitsøy</li>
            <li>Org.nr.:928096904</li>
            <li>Tlf.: 97516356</li>
            <li>annebo@online.no</li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default App;
